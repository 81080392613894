.banner-location {
  padding: 100px 0 0;
  position: relative; }
  @media (max-width: 767px) {
    .banner-location {
      background: #F3F3F3;
      padding: 40px 0 15px; } }
  .banner-location__box {
    background: #FFFFFF;
    cursor: auto;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%; }
    @media (max-width: 1199px) {
      .banner-location__box {
        padding: 25px; } }
    @media (max-width: 991px) {
      .banner-location__box {
        padding: 30px; } }
    @media (max-width: 767px) {
      .banner-location__box {
        background: transparent;
        padding: 15px 0; } }
  .banner-location__box-row {
    display: flex; }
    @media (max-width: 767px) {
      .banner-location__box-row--hidden-mobile {
        display: none; } }
  .banner-location__box-cell {
    color: #1c1417;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    width: 60px; }
    .banner-location__box-cell--link:hover {
      cursor: pointer; }
    .banner-location__box-cell--link-bold {
      font-weight: 700 !important; }
    @media (max-width: 1199px) {
      .banner-location__box-cell {
        line-height: 22px;
        width: 50px; } }
    @media (max-width: 991px) {
      .banner-location__box-cell {
        line-height: 25px; } }
    @media (max-width: 767px) {
      .banner-location__box-cell {
        line-height: 30px; } }
    .banner-location__box-cell:last-child {
      color: #1c1417;
      flex: 1;
      font-size: 14px;
      font-weight: 300;
      text-decoration: none; }
      @media (max-width: 767px) {
        .banner-location__box-cell:last-child {
          color: rgba(28, 20, 23, 0.5); } }
    .banner-location__box-cell--link {
      cursor: pointer; }
      .banner-location__box-cell--link:hover {
        color: #DB3645; }
  .banner-location__box-title {
    color: #1c1417;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 48px;
    text-align: left;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .banner-location__box-title {
        font-size: 18px; } }
  .banner-location__figure {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .banner-location__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .banner-location__overlay {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: left;
    left: 0;
    padding: 30px;
    position: absolute;
    top: 0;
    width: 100%; }
    @media (max-width: 767px) {
      .banner-location__overlay {
        display: none; } }
    .banner-location__overlay:before {
      background: rgba(28, 20, 23, 0);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: background 0.4s ease-in-out;
      width: 100%;
      z-index: 1; }
  .banner-location__section {
    cursor: pointer;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden; }
    @media (max-width: 767px) {
      .banner-location__section {
        padding-bottom: 0;
        height: auto; } }
    .banner-location__section:hover .banner-location__overlay:before {
      background: rgba(28, 20, 23, 0.3);
      transition: background 0.4s ease-in-out; }
    .banner-location__section:hover .banner-location__section-box {
      transform: translate3d(0, 0, 0);
      transition: transform 0.4s ease-in-out; }
  .banner-location__section-title {
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 700;
    position: relative; }
  .banner-location__section-box {
    align-items: flex-end;
    display: flex;
    height: 100%;
    left: 0;
    padding: 30px 30px 0;
    position: absolute;
    top: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.4s ease-in-out;
    width: 100%;
    z-index: 1; }
    @media (max-width: 767px) {
      .banner-location__section-box {
        padding: 0;
        position: static;
        transform: translate3d(0, 0, 0); } }
  .banner-location__title {
    color: #1c1417;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 40px;
    text-align: center; }
    @media (max-width: 767px) {
      .banner-location__title {
        font-size: 24px;
        letter-spacing: -0.33px;
        line-height: 40px;
        text-align: left; } }
  .banner-location__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 70px; }
    @media (max-width: 991px) {
      .banner-location__wrapper {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 50px; } }
    @media (max-width: 767px) {
      .banner-location__wrapper {
        grid-template-columns: 1fr;
        margin-top: 17px; } }

.banner-projects-title {
  color: #1c1417;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 70px;
  margin-top: -34px; }
  @media (max-width: 1199px) {
    .banner-projects-title {
      margin-top: 0; } }
  @media (max-width: 991px) {
    .banner-projects-title {
      margin-bottom: 50px; } }
  @media (max-width: 767px) {
    .banner-projects-title {
      font-size: 24px;
      letter-spacing: -0.33px;
      line-height: 40px;
      text-align: left; } }
